import * as React from "react";
import RegisterViewSigrid from "../views/RegisterViewSigrid";
import Topbar from "../components/Topbar";
import useTranslation from "../hooks/useTranslation";
import FootView from "../views/FootView";

const ElistaPage = (props: any) => {
  return (
    <>
      <RegisterViewSigrid
        pathName={props.location.pathname}
      ></RegisterViewSigrid>
    </>
  );
};

export default ElistaPage;
